import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import BlueCashCard from '../../assets/cards/blue_cash_preferred_card.png'
import BusinessGreenCard from '../../assets/cards/business_green_rewards_card.png'
import DocumentIcon from '../../assets/cards/document.png'
import GoldCard from '../../assets/cards/gold_card.png'
import PlatinumCard from '../../assets/cards/platinum_card.png'
import PlumCard from '../../assets/cards/plum_card.png'
import { StoreContext } from '../../components/App'
import { CardGrid } from '../../components/CardGrid/CardGrid'
import { NavMenu } from '../../components/NavMenu/NavMenu'
import { PageHeading } from '../../components/PageHeading/PageHeading'
import { TopBar } from '../../components/TopBar/TopBar'
import { NAV_ROUTE_LIST } from '../../store/Router.store'
import {
  SubpageContentWrapper,
  InnerAppContent,
  InnerAppLayout,
} from '../../styles/generic.styles'

const ConsumerCards = [
  {
    id: 'platinumCard',
    title: 'The Platinum Card®',
    label: 'View Agreement',
    image1Url: PlatinumCard,
    image2Url: DocumentIcon,
    url: 'contract',
  },
  {
    id: 'goldCard',
    title: 'American Express® Gold Card',
    label: 'View Agreement',
    image1Url: GoldCard,
    image2Url: DocumentIcon,
    url: 'contract',
  },
  {
    id: 'blueCashCard',
    title: 'Blue Cash Preferred® Card',
    label: 'View Agreement',
    image1Url: BlueCashCard,
    image2Url: DocumentIcon,
    url: 'contract',
  },
]

const CorporateCards = [
  {
    id: 'businessPlatinumCard',
    title: 'Business Platinum Card®',
    label: 'View Agreement',
    image1Url: PlatinumCard,
    image2Url: DocumentIcon,
    url: 'contract',
  },
  {
    id: 'businessGreenCard',
    title: 'Business Green Rewards Card',
    label: 'View Agreement',
    image1Url: BusinessGreenCard,
    image2Url: DocumentIcon,
    url: 'contract',
  },
  {
    id: 'plumCard',
    title: 'Plum Card®',
    label: 'View Agreement',
    image1Url: PlumCard,
    image2Url: DocumentIcon,
    url: 'contract',
  },
]

export const MyAgreementsPage = observer(() => {
  const store = useContext(StoreContext)

  return (
    <div>
      <TopBar />
      <InnerAppLayout>
        <NavMenu items={NAV_ROUTE_LIST} margin="24px 0 40px 0" />
        <InnerAppContent>
          <SubpageContentWrapper>
            <PageHeading
              title="Consumer Cards Agreements"
              subtitle="With the inspiration, personal advice, and tools to help you make your trip perfect."
            />
            <CardGrid items={ConsumerCards} />

            <PageHeading
              title="Business Cards Agreements"
              subtitle="With the inspiration, personal advice, and tools to help you make your trip perfect."
            />
            <CardGrid items={CorporateCards} />
          </SubpageContentWrapper>
        </InnerAppContent>
      </InnerAppLayout>
    </div>
  )
})
