import React, { useState } from 'react'
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

import { Document, Page } from 'react-pdf'
import { Container } from './PdfViewer.styles'

export const PdfViewer = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <Container>
      <Document
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        className="pdf-document-view"
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            className="pdf-document-page"
            scale={1.5}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </Container>
  )
}
