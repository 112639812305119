import { action, makeAutoObservable, runInAction } from 'mobx'

export const NAV_ROUTE_LIST = [
  {
    name: 'membership-benefits',
    label: 'Membership benefits',
    urls: ['/', '/membership-benefits'],
  },
  {
    name: 'my-cards',
    label: 'My cards',
    urls: ['/my-cards'],
  },
  {
    name: 'my-agreements',
    label: 'My agreements',
    urls: ['/my-agreements'],
  },
  {
    name: 'my-bills',
    label: 'My bills',
    urls: ['/my-bills'],
  },
  {
    name: 'my-data',
    label: 'My data',
    urls: ['/my-data'],
  },
  {
    name: 'my-security-center',
    label: 'My security center',
    urls: ['/my-security-center'],
  },
  {
    name: 'transfer-credit-balance',
    label: 'Transfer credit balance',
    urls: ['/transfer-credit-balance'],
  },
]

const routes = {
  signicatRestricted: {
    componentName: 'signicatRestricted',
    paths: ['access-denied', 'signicat-restricted'],
    title: 'Access Denied - Signicat Restricted',
    secure: false,
  },
  login: {
    componentName: 'login',
    paths: ['no', 'sv', 'login'],
    title: 'Signicat Merchants Dashboard - Please Login',
    secure: false,
  },
  loginSuccess: {
    componentName: 'loginSuccess',
    paths: ['login-success'],
    title: "Signicat Merchants Dashboard - You='re logged in",
    secure: false,
  },
  membershipBenefits: {
    componentName: 'membershipBenefits',
    paths: ['', '/', 'membership-benefits'],
    title: 'Self Service Portal - Membership Benefits',
    secure: true,
  },
  myCards: {
    componentName: 'myCards',
    paths: ['my-cards'],
    title: 'Self Service Portal - My Cards',
    secure: true,
  },
  myAgreements: {
    componentName: 'myAgreements',
    paths: ['my-agreements'],
    title: 'Self Service Portal - My Agreements',
    secure: true,
  },
  myBills: {
    componentName: 'myBills',
    paths: ['my-bills'],
    title: 'Self Service Portal - My Bills',
    secure: true,
  },
  myData: {
    componentName: 'myData',
    paths: ['my-data'],
    title: 'Self Service Portal - My Data',
    secure: true,
  },
  mySecurityCenter: {
    componentName: 'mySecurityCenter',
    paths: ['my-security-center'],
    title: 'Self Service Portal - My Security Center',
    secure: true,
  },
  contract: {
    componentName: 'contract',
    paths: ['contract'],
    title: 'Contract Agreement View',
    secure: true,
  },
  viewMyData: {
    componentName: 'viewMyData',
    paths: ['view-my-data'],
    title: 'My Data View',
    secure: true,
  },
  trasnferCreditBalance: {
    componentName: 'transferCreditBalance',
    paths: ['transfer-credit-balance'],
    title: 'Transfer Credit Balance',
    secure: true,
  },
  transferThanks: {
    componentName: 'transferThanks',
    paths: ['transfer-success'],
    title: 'Transfer Credit Balance - Request recieved',
    secure: true,
  },
}

export function getPageNameByPath(path: string) {
  let output

  Object.values(routes).forEach((route, index) => {
    const foundPath = route.paths.find((setPath) => setPath == path)

    if (foundPath !== undefined) {
      output = Object.values(routes)[index]
    }
  })

  return output
}

export class RouterStateStore {
  rootStore
  currentPath = '/'
  location

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.location = window.location
  }

  @action.bound setCurrentPath(path: string): void {
    this.currentPath = path
  }

  @action.bound setLocation(location: string): void {
    runInAction(() => {
      this.location = `${window.location.origin}/${location}`
      this.currentPath = location
    })

    history.pushState(
      {
        id: location,
      },
      location.toUpperCase(),
      `${window.location.origin}/${location}`
    )
  }
}
