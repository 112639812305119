import React from 'react'
import axios from 'axios'
import {
  CollapseArrow,
  Container,
  LogoutButton,
  UlContainer,
} from './NavMenu.styles'

import { AccountBoxIcon } from '../Icons/AccountBoxIcon'
import { CardMembershipIcon } from '../Icons/CardMembershipIcon'
import { CardsIcon } from '../Icons/CardsIcon'
import { DocumentScannerIcon } from '../Icons/DocumentScannerIcon'
import { ReceiptIcon } from '../Icons/ReceiptIcon'
import { SecurityIcon } from '../Icons/SecurityIcon'
import { LogoutIcon } from '../Icons/LogoutIcon'

interface NavMenuItem {
  name: string // needs to be unique
  label: string
  urls: string[]
  icon?: string
}

interface NavMenuProps {
  items: NavMenuItem[]
  margin?: string
}

export const NavMenu = ({ items, margin }: NavMenuProps) => {
  const [isExpanded, setIsExpanded] = React.useState(true)

  const getIcon = (icon: string) => {
    switch (icon) {
      case 'membership-benefits':
        return <CardMembershipIcon />
      case 'my-cards':
        return <CardsIcon />
      case 'my-agreements':
        return <DocumentScannerIcon />
      case 'my-data':
        return <AccountBoxIcon />
      case 'my-bills':
        return <ReceiptIcon />
      case 'my-security-center':
        return <SecurityIcon />
      default:
        return <DocumentScannerIcon />
    }
  }

  const logout = async () => {
    try {
      window.location.href = '/login'
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Container>
      <CollapseArrow
        closed={!isExpanded}
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7497 9.33203L11.083 13.9987L15.7497 18.6654"
            stroke="#016FD4"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </CollapseArrow>
      <UlContainer>
        {items.map((item) => {
          let className =
            item.urls.indexOf(window.location.pathname) !== -1 ? 'active' : ''

          // special case for contract page before routing changes
          if (
            window.location.pathname === '/contract' &&
            item.urls.indexOf('/my-agreements') !== -1
          ) {
            className = 'active'
          } else if (
            window.location.pathname === '/view-my-data' &&
            item.urls.indexOf('/my-data') !== -1
          ) {
            className = 'active'
          }

          return (
            <li key={item.name}>
              <a href={item.urls[0]} className={className}>
                <div className={className}>
                  {getIcon(item.name)}
                  {item.label}
                </div>
              </a>
            </li>
          )
        })}
      </UlContainer>

      <LogoutButton onClick={() => logout()}>
        <LogoutIcon />
        Log out
      </LogoutButton>
    </Container>
  )
}
