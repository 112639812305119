import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 55px;
  justify-content: space-between;
  background-color: white;
`

export const BurgerIcon = styled.svg`
  width: 19px;
  height: 14px;
  margin: 20px 20px;
`

export const AmexLogo = styled.img`
  width: 106px;
  height: 40px;
  margin: 6px 0 6px 0;
`
