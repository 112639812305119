import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { NAV_ROUTE_LIST } from '../../store/Router.store'
import SpainPhoto from '../../assets/stock/spain1.jpg'
import SwedenPhoto from '../../assets/stock/sweden1.jpg'
import NorwayPhoto from '../../assets/stock/norway1.jpg'
import Banner from '../../assets/stock/banner1.jpg'
import { StoreContext } from '../../components/App'

import { NavMenu } from '../../components/NavMenu/NavMenu'
import { PageHeading } from '../../components/PageHeading/PageHeading'
import { TopBar } from '../../components/TopBar/TopBar'
import {
  SubpageContentWrapper,
  InnerAppContent,
  InnerAppLayout,
} from '../../styles/generic.styles'
import {
  Title,
  MainImage,
  CardContainer,
  CardGridContainer,
  TextBody,
  ImageTextWrapper,
  BannerWrapper,
  BannerImage,
} from './MembershipBenefitsPage.styles'
import { Button, ButtonAmex } from '../../components/Button/Button'

const ConsumerCards = [
  {
    title: 'Relax in the sun in Spain',
    body: 'Lorem ipsum dolor sit amet consectetur. Eleifend aliquam neque ullamcorper adipiscing id. Etiam lacus elementum facilisis commodo nisl.',
    label: 'Book now with Amex',
    imageUrl: SpainPhoto,
    url: '/',
  },
  {
    title: 'Mountain snow in Sweden',
    body: 'Lorem ipsum dolor sit amet consectetur. Eleifend aliquam neque ullamcorper adipiscing id. Etiam lacus elementum facilisis commodo nisl.',
    label: 'Plan it with Amex',
    imageUrl: SwedenPhoto,
    url: '/',
  },
  {
    title: 'Family adventure in Norway',
    body: 'Lorem ipsum dolor sit amet consectetur. Eleifend aliquam neque ullamcorper adipiscing id. Etiam lacus elementum facilisis commodo nisl.',
    label: 'Find family fun with Amex',
    imageUrl: NorwayPhoto,
    url: '/',
  },
]

interface CardGridInterface {
  items: CardInterface[]
}

interface CardInterface {
  title: string
  body: string
  label: string
  imageUrl: string
  image2Url?: string
  url: string
}

export const MembershipBenefitsPage = observer(() => {
  const store = useContext(StoreContext)

  const CardGrid = ({ items }: CardGridInterface) => {
    return (
      <CardGridContainer>
        {items.map((item, index) => {
          const { title, body, label, imageUrl, url } = item
          return (
            <CardContainer key={'card' + index}>
              <ImageTextWrapper>
                <MainImage src={imageUrl} />
                <Title>{title}</Title>
                <TextBody>{body}</TextBody>
              </ImageTextWrapper>

              <Button
                label={label}
                styles={{ ...ButtonAmex, width: '100%' }}
                onClick={() => {
                  store.Router.setLocation(url)
                }}
              />
            </CardContainer>
          )
        })}
      </CardGridContainer>
    )
  }

  return (
    <div>
      <TopBar />
      <InnerAppLayout>
        <NavMenu items={NAV_ROUTE_LIST} margin="24px 0 40px 0" />
        <InnerAppContent>
          <SubpageContentWrapper>
            <PageHeading
              title="Membership benefits"
              subtitle="With the inspiration, personal advice, and tools to help you make your trip perfect."
            />
            <CardGrid items={ConsumerCards} />
            <PageHeading
              title="Discover the world of benefits and rewards from American Express."
              subtitle="Browse membership benefits, rewards, and offers. Find a credit card that works for your lifestyle.
            Explore Card Benefits."
            />
            <BannerWrapper>
              <BannerImage src={Banner} />
              <h1>My island paradise</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur. Eleifend aliquam neque
                ullamcorper adipiscing id. Etiam lacus elementum facilisis
                commodo nisl.
              </p>
            </BannerWrapper>
          </SubpageContentWrapper>
        </InnerAppContent>
      </InnerAppLayout>
    </div>
  )
})
