import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { ContractPage } from '../pages/ContractPage/ContractPage'
import { LoginPage } from '../pages/LoginPage/LoginPage'
import { LoginSuccessPage } from '../pages/LoginSuccessPage/LoginSuccessPage'
import { MembershipBenefitsPage } from '../pages/MembershipBenefitsPage/MembershipBenefitsPage'
import { MyAgreementsPage } from '../pages/MyAgreementsPage/MyAgreementsPage'
import { MyDataPage } from '../pages/MyDataPage/MyDataPage'
import { NotFoundPage } from '../pages/NotFoundPage'
import { SelfServicePage } from '../pages/SelfServicePage/SelfServicePage'
import { SignicatRestrictedPage } from '../pages/SignicatRestricted'
import { ThanksPage } from '../pages/ThanksPage/ThanksPage'
import { TransferCreditBalancePage } from '../pages/TransferCreditBalancePage/TransferCreditBalancePage'
import { ViewMyDataPage } from '../pages/ViewMyDataPage/ViewMyDataPage'
import { NAV_ROUTE_LIST, getPageNameByPath } from '../store/Router.store'
import { ContentWrap } from '../styles/generic.styles'
import { StoreContext } from './App'
import { Loading } from './Loading/Loading'
import { MobileNavMenu } from './MobileNavMenu/MobileNavMenu'

const LoadWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const MOBILE_NAV_MENU_ROUTES = [
  '',
  'membership-benefits',
  'my-cards',
  'my-agreements',
  'my-bills',
  'my-data',
  'my-security-center',
  'contract',
  'view-my-data',
]

const PAGES = {
  signicatRestricted: SignicatRestrictedPage,
  login: LoginPage,
  loginSuccess: LoginSuccessPage,

  membershipBenefits: MembershipBenefitsPage,
  myCards: SelfServicePage,
  myAgreements: MyAgreementsPage,
  myBills: SelfServicePage,
  myData: MyDataPage,
  mySecurityCenter: SelfServicePage,
  contract: ContractPage,
  viewMyData: ViewMyDataPage,
  transferCreditBalance: TransferCreditBalancePage,
  transferThanks: ThanksPage,
}

export const ContentRouter: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const { theme, initialAuthCheck, isAuthorized } = store.AppState

  const path = window.location.pathname.substring(1)

  const renderScenarioPage = () => {
    if (path === 'transfer-success') {
      return <ThanksPage />
    }

    return <TransferCreditBalancePage />

    const currentPage = getPageNameByPath(store.Router.currentPath)

    let Handler = PAGES.login

    let loginPath = localStorage.getItem('country')
    if (loginPath !== 'sv' && loginPath !== 'no') {
      loginPath = 'login'
    }

    // if (currentPage && currentPage.secure && !store.AppState.authorization) {
    //   // (unathorized) trying access secured route case
    //   store.Router.setLocation(loginPath)
    // } else if (currentPage && store.AppState.authorization) {
    //   // (authorized) route change
    //   store.Router.setLocation(window.location.pathname.substring(1))
    //   Handler = PAGES[currentPage.componentName] || NotFoundPage
    // } else if (store.AppState.authorization) {
    //   // (authorized) route not found case
    //   Handler = PAGES.login
    // } else {
    //   // fallback case
    //   store.Router.setLocation(loginPath)
    //   Handler = PAGES.login
    // }

    const accessCheck = localStorage.getItem('authorized')

    if (accessCheck !== 'true') {
      // user not having proper value setup in localstorage to test website (to be removed on production)
      store.Router.setLocation('access-denied')
      Handler = PAGES.signicatRestricted
    } else if (currentPage?.secure && !isAuthorized) {
      // (unathorized) trying access secured route case
      store.Router.setLocation(loginPath)
      Handler = PAGES.login

      // for testing purposes
      //store.Router.setLocation(path)
      //Handler = PAGES[currentPage?.componentName] || NotFoundPage
    } else if (currentPage) {
      // route change with not found case handled
      store.Router.setLocation(path)
      Handler = PAGES[currentPage?.componentName] || NotFoundPage
    }

    return <Handler />
  }

  useEffect(() => {
    store.AppState.checkLogin()
  }, [])

  return (
    <div className="content-router">
      <ContentWrap>
        {initialAuthCheck ? (
          renderScenarioPage()
        ) : (
          <LoadWrap>
            <Loading
              width="48px"
              {...theme.loadingSpinner}
              padding="0 0"
              thickness={4}
            />
          </LoadWrap>
        )}
      </ContentWrap>

      {/* {MOBILE_NAV_MENU_ROUTES.includes(path) && (
        <MobileNavMenu items={NAV_ROUTE_LIST} />
      )} */}
    </div>
  )
})
