import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import styled from 'styled-components'
import { tablet } from '../../constants/breakpoints'

export const Container = styled.div`
  width: 860px;

  .pdf-document-view {
    width: 860px;
  }

  .pdf-document-page {
    margin-bottom: 30px;
    box-shadow: 0px 0px 19.4px 0px rgba(0, 0, 0, 0.08);
  }

  @media (max-width: ${tablet}) {
    width: 100%;

    .pdf-document-view,
    .pdf-document-page,
    .react-pdf__Page__canvas {
      min-width: 0px !important;
      width: 100% !important;
    }
  }
`
