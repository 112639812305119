import React from 'react'
import styled from 'styled-components'

interface ContainerProps {
  width?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '')};
  aspect-ratio: 1;
  padding: ${(props) => (props.padding ? props.padding : '')};

  svg {
    width: 100%;
    height: auto;
  }
`

interface CardMembershipIconProps {
  width?: string
  padding?: string
}

export const CardMembershipIcon = ({
  width,
  padding,
}: CardMembershipIconProps) => {
  return (
    <Container width={width ? width : '24px'} padding={padding ? padding : '0'}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="card_membership">
          <path
            id="Vector"
            d="M20 2H4C2.89 2 2 2.89 2 4V15C2 16.11 2.89 17 4 17H8V22L12 20L16 22V17H20C21.11 17 22 16.11 22 15V4C22 2.89 21.11 2 20 2ZM20 15H4V13H20V15ZM20 10H4V4H20V10Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </Container>
  )
}
