import styled from 'styled-components'

interface BackButtonInterface {
  onClick?: () => void
}

export const Container = styled.a<BackButtonInterface>`
  margin-top: 17px;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  color: inherit;
  text-decoration: none;
  display: block;

  :hover {
    color: inherit;
    text-decoration: none;
  }

  :active {
    color: inherit;
    text-decoration: none;
  }

  svg {
    margin-right: 10px;
  }
`
