import styled from 'styled-components'

export const CardContainer = styled.div`
  /* box-sizing: border-box; */
  background-color: #ffffff;
  display: inline-flex;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 0px 9.5px 0px rgba(0, 0, 0, 0.1);
  width: 280px;
  height: 508px;
`

export const CardGridContainer = styled.div`
  width: 100%;
  /* display: grid;
  grid-template-columns: repeat(3, minmax(auto, 450px)); */
  gap: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const ImageTextWrapper = styled.div``

export const Title = styled.h1`
  width: 100%;
  text-align: left;
  color: #3e4043;
  font-family: 'Inter';
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 136.364% */
  margin: 20px 0 10px 0;
  overflow: hidden;

  /* text-align: center; */
`

export const TextBody = styled.p`
  color: #797e8a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin: 0;
`

export const MainImage = styled.img`
  width: 280px;
  height: 250px;

  object-fit: cover;
`

export const BannerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  background: #fff;
  box-shadow: 0px 0px 9.5px 0px rgba(0, 0, 0, 0.1);

  h1 {
    color: #3e4043;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 136.364% */
    margin: 20px 0 10px 0;
  }

  p {
    color: #797e8a;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin: 0 0 40px 0;
  }
`

export const BannerImage = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
`
