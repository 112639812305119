import styled from 'styled-components'

export const Container = styled.div`
  /* width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 450px));
  gap: 18px; */

  width: 100%;
  gap: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
