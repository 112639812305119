import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../App'
import { Container } from './FooBar.styles'

export const FooBar = observer(() => {
  const store = useContext(StoreContext)

  return <Container>footer</Container>
})
