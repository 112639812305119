import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import MockedBanner from '../../assets/mockedMobileFill.png'
import { StoreContext } from '../../components/App'
import { Button, ButtonAmex } from '../../components/Button/Button'
import { SBankIDLogo } from '../../components/Icons/SBankIDLogo'
import { Loading } from '../../components/Loading/Loading'
import { MockedTop } from '../../components/MockedTop/MockedTop'
import { LoginButton } from '../LoginPage/LoginPage.styles'
import {
  BankIDButton,
  Banner,
  Container,
  DecisionButton,
  Description,
  FormWrapper,
  Header,
  Input,
  InputLabel,
  StepWrapper,
} from './TransferCreditBalance.styles'

export const TransferCreditBalancePage = observer(() => {
  const store = useContext(StoreContext)

  const [currentStep, setCurrentStep] = React.useState(0)

  const [sbidLoading, setSbidLoading] = React.useState(false)

  const handleLogin = async () => {
    setSbidLoading(true)

    const location = new URL(
      'https://onboarding-amex-proxy-89e69358ff2a.herokuapp.com/create-transaction'
    )
    location.searchParams.set('method', 'sbid')
    location.searchParams.set(
      'redirectUrl',
      `${window.origin}/transfer-success`
    )

    window.location.href = location.toString()
  }

  const Step1 = () => {
    return (
      <>
        <FormWrapper>
          <Header> Transfer credit balance </Header>
          <Description>
            Select your bank ID methid to sign the contract
          </Description>
          <DecisionButton
            onClick={() => {
              setCurrentStep(1)
            }}
          >
            Transfer credit to my bank account
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
            >
              <path d="M1 1L6 6L1 11" stroke="#06337A" stroke-linecap="round" />
            </svg>
          </DecisionButton>

          <DecisionButton
            onClick={() => {
              setCurrentStep(1)
            }}
          >
            Transfer credit to another Amex account
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
            >
              <path d="M1 1L6 6L1 11" stroke="#06337A" stroke-linecap="round" />
            </svg>
          </DecisionButton>
        </FormWrapper>
      </>
    )
  }

  const Step2 = () => {
    return (
      <>
        <FormWrapper>
          <Header> Enter Bank details </Header>
          <InputLabel>Clearing number</InputLabel>
          <Input />
          <InputLabel>Account number</InputLabel>
          <Input />
        </FormWrapper>
        <Button
          label="Transfer surplus"
          styles={{ ...ButtonAmex, width: '100%', height: '50px' }}
          onClick={() => setCurrentStep(2)}
        />
      </>
    )
  }

  const Step3 = () => {
    return (
      <>
        <FormWrapper>
          <Header> Please enter your card number </Header>

          <InputLabel>Card number</InputLabel>
          <Input />
        </FormWrapper>
        <Button
          label="Next"
          styles={{ ...ButtonAmex, width: '100%', height: '50px' }}
          onClick={() => setCurrentStep(3)}
        />
      </>
    )
  }

  const Step4 = () => {
    return (
      <>
        <FormWrapper>
          <Header> Please enter your birthday </Header>

          <InputLabel>Date of birth</InputLabel>
          <Input />
        </FormWrapper>
        <Button
          label="Next"
          styles={{ ...ButtonAmex, width: '100%', height: '50px' }}
          onClick={() => setCurrentStep(4)}
        />
      </>
    )
  }

  const Step5 = () => {
    return (
      <>
        <FormWrapper>
          <Header> Sign with Mobile BankID </Header>

          <BankIDButton
            bgColor="#043F51"
            onClick={(e) => {
              e.preventDefault()
              handleLogin()
            }}
          >
            {sbidLoading ? (
              <Loading
                bgColor="#043F51"
                primary="#eeeeee"
                width="25px"
                padding="0 0"
                thickness={3}
              />
            ) : (
              <SBankIDLogo color="#eeeeee" />
            )}
          </BankIDButton>
        </FormWrapper>
      </>
    )
  }

  const STEPS = [<Step1 />, <Step2 />, <Step3 />, <Step4 />, <Step5 />]

  return (
    <Container>
      <MockedTop />
      <Banner src={MockedBanner} />
      <StepWrapper>{STEPS[currentStep]}</StepWrapper>
    </Container>
  )
})
