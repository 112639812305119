import styled from 'styled-components'
import { tablet } from '../../constants/breakpoints'

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 290px;
  min-width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(180deg, #0c224b 0%, #0071d7 100%);
  border-right: 1px solid #dfe2ec;
  padding: 30px 24px 24px 24px;

  @media (max-width: ${tablet}) {
    display: none;
  }
`

export const UlContainer = styled.ul`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: 'Inter';

  list-style-type: none;

  li {
    width: 100%;

    & > a {
      display: block;
      text-decoration: none;
      padding: 20px 0;
      display: flex;
      align-items: center;

      & > div {
        height: 24px;
        display: flex;
        align-items: center;
        gap: 10px;

        width: 100%;
        color: #87a1df;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 14px;
        box-sizing: border-box;

        &.active {
          border-left: 4px solid #ffffff;
          padding-left: 10px;
          color: #ffffff;
        }

        &:hover {
          color: #ddd;
        }
      }
    }
  }
`

export const LogoutButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 15px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    color: #87a1df;
  }
`

export const CollapseArrow = styled.div<{ closed?: boolean }>`
  position: absolute;
  top: 14px;
  right: -14px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  border: 1px solid #dfe2ec;
  background: var(--white, #fff);
  cursor: pointer;

  svg {
    transform: rotate(${(props) => (props.closed ? '180deg' : '0deg')});
    transition: transform 0.3s ease-in-out;
  }
`
