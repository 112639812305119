import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import MockedBanner from '../../assets/mockedMobileFill.png'
import { StoreContext } from '../../components/App'
import { Button, ButtonAmex } from '../../components/Button/Button'
import { MockedTop } from '../../components/MockedTop/MockedTop'
import {
  Banner,
  Container,
  Description,
  Header,
  StepWrapper,
} from './ThanksPage.styles'
import {
  FormWrapper,
  Input,
  InputLabel,
} from '../TransferCreditBalancePage/TransferCreditBalance.styles'

export const ThanksPage = observer(() => {
  const store = useContext(StoreContext)

  return (
    <Container>
      <MockedTop />
      <Banner src={MockedBanner} />
      <StepWrapper>
        <FormWrapper>
          <Header>Thank you! We have received your request.</Header>
          <Description>
            The transfer will be affected within 5 business days
          </Description>
          <InputLabel>Account number</InputLabel>
          <Input />
        </FormWrapper>

        <Button
          label="Send"
          onClick={() => console.log('')}
          styles={{ ...ButtonAmex, width: '100%', height: '50px' }}
        />
      </StepWrapper>
    </Container>
  )
})
