import styled from 'styled-components'
import { mobile } from '../../constants/breakpoints'

export const BannerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  background: #fff;
  box-shadow: 0px 0px 9.5px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: ${mobile}) {
    padding: 15px;
  }
`

export const BannerImage = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
  /* border-radius: 10px; */
`

export const BannerHeader = styled.h1`
  color: #3e4043;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 136.364% */
  margin: 20px 0 10px 0;
`

export const BannerText = styled.p`
  color: #797e8a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin: 0;
`

export const BannerButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 0;
  gap: 20px;
  flex-wrap: wrap;

  a {
    width: 280px;
  }

  @media (max-width: 1320px) {
    justify-content: center;
  }
`

export const BannerFooter = styled.div`
  color: #808694;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  width: 100%;
`

export const BannerLink = styled.a`
  color: #808694;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
  text-decoration-line: underline;
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover {
  }

  &:active {
    color: #808694;
  }

  &:visited {
    color: #808694;
  }

  &:focus {
    color: #808694;
  }
`
