import React from 'react'
import styled from 'styled-components'

interface ContainerProps {
  width?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '')};
  aspect-ratio: 1;
  padding: ${(props) => (props.padding ? props.padding : '')};

  svg {
    width: 100%;
    height: auto;
  }
`

interface SecurityIconProps {
  width?: string
  padding?: string
}

export const SecurityIcon = ({ width, padding }: SecurityIconProps) => {
  return (
    <Container width={width ? width : '24px'} padding={padding ? padding : '0'}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="security">
          <path
            id="Vector"
            d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM12 11.99H19C18.47 16.11 15.72 19.78 12 20.93V12H5V6.3L12 3.19V11.99Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </Container>
  )
}
