import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../App'
import { Container } from './BackButton.styles'

export const BackButton = observer(({ location }) => {
  const store = useContext(StoreContext)

  return (
    <Container onClick={() => store.Router.setLocation(location)}>
      <svg
        version="1.1"
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector 311"
          d="M7 1L2 6L7 11"
          stroke="#3E4043"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
      Back
    </Container>
  )
})
