import styled from 'styled-components'

export const Container = styled.div`
  background-color: #ffffff;
  display: inline-flex;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  box-shadow: 0px 0px 19.4px 0px rgba(0, 0, 0, 0.08);
  width: 280px;
  height: 508px;
`

export const Title = styled.h1`
  width: 100%;
  text-align: left;
  color: #3e4043;
  font-family: 'Inter';
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 136.364% */
  height: 60px;
  margin: 0;
  overflow: hidden;
  /* text-align: center; */
`

export const MainImage = styled.img``

export const SecondaryImage = styled.div`
  padding-bottom: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #e0d2d2;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
`
