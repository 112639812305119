import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import blueCashCard from '../../assets/blue-cash-card.pdf'
import businessGreenCard from '../../assets/business-green-card.pdf'
import businessPlatinumCard from '../../assets/business-platinum-card.pdf'
import goldCard from '../../assets/gold-card.pdf'
import PdfMock from '../../assets/pdfMock.pdf'
import platinumCard from '../../assets/platinum-card.pdf'
import plumCard from '../../assets/plum-card.pdf'
import { StoreContext } from '../../components/App'
import { BackButton } from '../../components/BackButton/BackButton'
import { Button, ButtonAmex } from '../../components/Button/Button'
import { NavMenu } from '../../components/NavMenu/NavMenu'
import { PageHeading } from '../../components/PageHeading/PageHeading'
import { PdfViewer } from '../../components/PdfViewer/PdfViewer'
import { TopBar } from '../../components/TopBar/TopBar'
import { NAV_ROUTE_LIST } from '../../store/Router.store'
import {
  SubpageContentWrapper,
  InnerAppContent,
  InnerAppLayout,
} from '../../styles/generic.styles'

const idMap = {
  platinumCard: platinumCard,
  goldCard: goldCard,
  blueCashCard: blueCashCard,
  businessPlatinumCard: businessPlatinumCard,
  businessGreenCard: businessGreenCard,
  plumCard: plumCard,
}

export const ContractPage = observer(() => {
  const [docUrl, setDocUrl] = useState(PdfMock)
  const store = useContext(StoreContext)
  const { activeCard } = store.AppState

  useEffect(() => {
    if (activeCard && activeCard.length) {
      setDocUrl(idMap[activeCard])
    }
  }, [])

  return (
    <div>
      <TopBar />
      <InnerAppLayout>
        <NavMenu items={NAV_ROUTE_LIST} margin="24px 0 40px 0" />
        <InnerAppContent>
          <SubpageContentWrapper>
            <BackButton location="my-agreements" />
            <PageHeading
              title="The Platinum Card® Agreement"
              subtitle="With the inspiration, personal advice, and tools to help you make your trip perfect."
              margin="15px 0 32px"
            >
              <Button
                label="Download PDF"
                styles={{ ...ButtonAmex, width: '180px' }}
                onClick={() => {
                  window.open(PdfMock, '_blank')
                }}
              />
            </PageHeading>
            <PdfViewer fileUrl={docUrl} />
          </SubpageContentWrapper>
        </InnerAppContent>
      </InnerAppLayout>
    </div>
  )
})
