import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;

  box-sizing: border-box;
`

export const Banner = styled.img`
  /* width: 100%;
  aspect-ratio: 225/390; */
  width: 390px;
  height: 225px;
`

export const Header = styled.h1`
  color: #06337a;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: 100%;
  margin: 0 0 10px 0;
`

export const Spacer = styled.div`
  width: 100%;
  height: 40px;
`

export const Description = styled.p`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 24px */

  width: 100%;
  margin: 0 0 40px 0;
`

export const DecisionButton = styled.button`
  /* button css reset */
  border: none;
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;

  width: 100%;
  height: 76px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  gap: 40px;
  margin-bottom: 15px;
`

export const InputLabel = styled.label`
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  margin: 0 0 6px 0;
  display: block;
  width: 100%;
`

export const Input = styled.input`
  width: 100%;
  height: 44px;
  border-radius: 4.8px;
  border: 1px solid #ced4da;
  background: var(--white, #fff);
  margin-bottom: 20px;
`

export const Footer = styled.div`
  width: 100%;
`

export const BankIDButton = styled.button<{
  bgColor?: string
}>`
  margin-top: 80px;
  border: none;
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;

  border-radius: 4px;
  background: ${(props) => (props.bgColor ? props.bgColor : '#0071d7')};
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
`
