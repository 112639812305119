import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { Button, ButtonAmex } from '../../../components/Button/Button'
import { StoreContext } from '../../App'
import { Container, MainImage, SecondaryImage, Title } from './Card.styles'

export interface CardInterface {
  id: string
  title: string
  label: string
  image1Url: string
  image2Url?: string
  url: string
}

export const Card = observer(
  ({ id, title, label, image1Url, image2Url, url }: CardInterface) => {
    const store = useContext(StoreContext)

    return (
      <Container>
        {image2Url && (
          <SecondaryImage>
            <img src={image2Url} />
          </SecondaryImage>
        )}
        {/* <CardContainer> */}
        <MainImage src={image1Url} />
        <Title>{title}</Title>
        {/* </CardContainer> */}
        <Button
          label={label}
          styles={{ ...ButtonAmex, width: '100%' }}
          onClick={() => {
            store.AppState.setActiveCard(id)
            store.Router.setLocation(url)
          }}
        />
      </Container>
    )
  }
)
