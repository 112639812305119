import React from 'react'
import styled from 'styled-components'

interface ContainerProps {
  width?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '')};
  aspect-ratio: 1;
  padding: ${(props) => (props.padding ? props.padding : '')};

  svg {
    width: 100%;
    height: auto;
  }
`

interface ReceiptIconProps {
  width?: string
  padding?: string
}

export const ReceiptIcon = ({ width, padding }: ReceiptIconProps) => {
  return (
    <Container width={width ? width : '24px'} padding={padding ? padding : '0'}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="receipt">
          <path
            id="Ellipse 19"
            d="M24 3.5C24 5.433 22.433 7 20.5 7C18.567 7 17 5.433 17 3.5C17 1.567 18.567 0 20.5 0C22.433 0 24 1.567 24 3.5Z"
            fill="#ffb23e"
          />
          <path
            id="Subtract"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1635 2.16351L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.5 20.5L15 22L16.5 20.5L18 22L19.5 20.5L21 22V8.97758C20.8353 8.99242 20.6685 9 20.5 9C19.98 9 19.4768 8.92783 19 8.79297V19.09H5V4.91H15.1824C15.0634 4.46001 15 3.98741 15 3.5C15 3.03906 15.0567 2.59137 15.1635 2.16351ZM18 8.40029C17.327 8.05626 16.7342 7.57767 16.2572 7H6V9H18V8.40029ZM18 17V15H6V17H18ZM18 13V11H6V13H18Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </Container>
  )
}
