import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { NAV_ROUTE_LIST } from '../../store/Router.store'
import { StoreContext } from '../../components/App'
import { NavMenu } from '../../components/NavMenu/NavMenu'
import { PageHeading } from '../../components/PageHeading/PageHeading'
import { TopBar } from '../../components/TopBar/TopBar'
import {
  SubpageContentWrapper,
  InnerAppContent,
  InnerAppLayout,
} from '../../styles/generic.styles'

export const SelfServicePage = observer(() => {
  const store = useContext(StoreContext)

  const path = window.location.pathname

  return (
    <div>
      <TopBar />
      <InnerAppLayout>
        <NavMenu items={NAV_ROUTE_LIST} margin="24px 0 40px 0" />
        <InnerAppContent>
          <SubpageContentWrapper>
            <PageHeading
              title={
                NAV_ROUTE_LIST.filter((route) => route.urls.includes(path))[0]
                  ?.label
              }
              subtitle="Access Denied"
            ></PageHeading>
          </SubpageContentWrapper>
        </InnerAppContent>
      </InnerAppLayout>
    </div>
  )
})
