import styled from 'styled-components'
import { mobile } from '../../constants/breakpoints'

export const FormWrapper = styled.div`
  box-sizing: border-box;
  background: #fff;
  padding: 30px 70px 50px 70px;
  box-shadow: 0px 0px 9.5px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto repeat(1fr);
  gap: 20px 40px;
  margin-bottom: 32px;

  @media (max-width: ${mobile}) {
    grid-template-columns: 1fr;
    padding: 30px 20px 50px 20px;
  }
`

export const FormHeader = styled.h1`
  color: #3e4043;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 136.364% */
  margin: 0 0 20px -40px;

  grid-column: 1 / 3;
  grid-row: 1;

  @media (max-width: ${mobile}) {
    grid-column: 1;
    margin: 0 0 20px 0;
  }
`
export const InputContainer = styled.div``

export const InputLabel = styled.label`
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  display: block;
`
export const Input = styled.input`
  border-radius: 5px;
  border: 1px solid #8e9092;
  background: #f9fbff;
  height: 50px;
  width: 100%;
`
