import React from 'react'
import styled from 'styled-components'

interface ContainerProps {
  width?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '')};
  aspect-ratio: 1;
  padding: ${(props) => (props.padding ? props.padding : '')};

  svg {
    width: 100%;
    height: auto;
  }
`

interface DocumentScannerIconProps {
  width?: string
  padding?: string
}

export const DocumentScannerIcon = ({
  width,
  padding,
}: DocumentScannerIconProps) => {
  return (
    <Container width={width ? width : '24px'} padding={padding ? padding : '0'}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="document_scanner">
          <path
            id="Vector"
            d="M18 5H6V19H18V5ZM20 19C20 20.1 19.1 21 18 21H6C4.9 21 4 20.1 4 19V5C4 3.9 4.9 3 6 3H18C19.1 3 20 3.9 20 5V19ZM16 7H8V9H16V7ZM16 11H8V13H16V11ZM16 15H8V17H16V15Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </Container>
  )
}
