import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { NAV_ROUTE_LIST } from '../../store/Router.store'

import Banner from '../../assets/stock/banner2.jpg'
import { StoreContext } from '../../components/App'

import { NavMenu } from '../../components/NavMenu/NavMenu'
import { PageHeading } from '../../components/PageHeading/PageHeading'
import { TopBar } from '../../components/TopBar/TopBar'
import {
  InnerAppContent,
  InnerAppLayout,
  SubpageContentWrapper,
} from '../../styles/generic.styles'
import { BackButtonLink } from '../../components/BackButtonLink/BackButtonLink'
import {
  FormHeader,
  FormWrapper,
  Input,
  InputContainer,
  InputLabel,
} from './ViewMyDataPage.styles'
import { Button, ButtonAmex } from '../../components/Button/Button'

export const ViewMyDataPage = observer(() => {
  const store = useContext(StoreContext)

  return (
    <div>
      <TopBar />
      <InnerAppLayout>
        <NavMenu items={NAV_ROUTE_LIST} margin="24px 0 40px 0" />
        <InnerAppContent>
          <SubpageContentWrapper>
            <BackButtonLink href="my-data" />
            <PageHeading
              title="Basic information that Amex stores."
              subtitle="In respect of the data subject enforcing their rights, it should first be noted that withdrawing consent, i.e. removing the controller's right to use your data, is not the same as the right of erasure. Consent is specific and unambiguous and is given for a particular activity."
            />

            <FormWrapper>
              <FormHeader>Identity data</FormHeader>

              <InputContainer>
                <InputLabel>First name</InputLabel>
                <Input />
              </InputContainer>
              <InputContainer>
                <InputLabel>Last name</InputLabel>
                <Input />
              </InputContainer>
              <InputContainer>
                <InputLabel>National identity number</InputLabel>
                <Input />
              </InputContainer>
              <InputContainer>
                <InputLabel>Date of birth</InputLabel>
                <Input />
              </InputContainer>
              <InputContainer>
                <InputLabel>Start date of membership</InputLabel>
                <Input />
              </InputContainer>
            </FormWrapper>

            <FormWrapper>
              <FormHeader>Address</FormHeader>

              <InputContainer>
                <InputLabel>Street</InputLabel>
                <Input />
              </InputContainer>
              <InputContainer>
                <InputLabel>House number</InputLabel>
                <Input />
              </InputContainer>
              <InputContainer>
                <InputLabel>City</InputLabel>
                <Input />
              </InputContainer>
              <InputContainer>
                <InputLabel>Postal code</InputLabel>
                <Input />
              </InputContainer>
              <InputContainer>
                <InputLabel>Country</InputLabel>
                <Input />
              </InputContainer>
            </FormWrapper>
          </SubpageContentWrapper>
        </InnerAppContent>
      </InnerAppLayout>
    </div>
  )
})
