import React from 'react'
import { StyledButtonLink } from './ButtonLink.styles'

export const ButtonSmall = {
  padding: '8px',
  borderRadius: '4px',
  fontSize: '14px',
  lineHeight: '20px',
  width: 'max-content',
}

export const ButtonBordered = {
  bgColor: '#fff',
  border: '1px solid #D9D0EA',
  color: 'rgba(42, 0, 98, 1)',
}

export const ButtonAmex = {
  bgColor: '#0071D7',
  border: 'none',
  fontFamily: 'Roboto',
  color: '#fff',
  fontSize: '14px',
  fontWeight: '500',
  borderRadius: '4px',
  padding: '12px 34px 8px 34px',
}

interface ButtonProps {
  label: string
  href?: string
  type?: 'button' | 'reset' | 'submit'
  styles?: {
    width?: string
    minWidth?: string
    fontFamily?: string
    fontSize?: string
    fontWeight?: string
    lineHeight?: string
    bgColor?: string
    padding?: string
    margin?: string
    border?: string
    outline?: string
    borderRadius?: string
  }
}

export const ButtonLink = ({ label, styles, type, href }: ButtonProps) => {
  return (
    <StyledButtonLink {...styles} href={href} type={type}>
      {label}
    </StyledButtonLink>
  )
}
