import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../App'
import { Card, CardInterface } from './Card/Card'
import { Container } from './CardGrid.styles'

interface CardGridInterface {
  items: CardInterface[]
}

export const CardGrid = observer(({ items }: CardGridInterface) => {
  const store = useContext(StoreContext)

  function renderItems() {
    return items.map((item, index) => {
      return <Card {...item} key={`${index}-${item.title}`} />
    })
  }

  return <Container>{renderItems()}</Container>
})
