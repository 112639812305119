import styled from 'styled-components'
import { tablet } from '../../constants/breakpoints'

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 38px;
`

export const RightSide = styled.div`
  display: flex;
  gap: 30px;
`

export const UserWrapper = styled.div`
  display: flex;

  /* height: 100%; */
  gap: 0 12px;

  @media (max-width: ${tablet}) {
    display: none;
  }
`

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`

export const UserInitials = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4f6f8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
`

export const UserName = styled.span`
  color: #51596d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: center;
`

export const UserRole = styled.span`
  color: #929cb6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: center;
`
