import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { NAV_ROUTE_LIST } from '../../store/Router.store'

import Banner from '../../assets/stock/banner2.jpg'
import { StoreContext } from '../../components/App'

import { NavMenu } from '../../components/NavMenu/NavMenu'
import { PageHeading } from '../../components/PageHeading/PageHeading'
import { TopBar } from '../../components/TopBar/TopBar'
import {
  SubpageContentWrapper,
  InnerAppContent,
  InnerAppLayout,
} from '../../styles/generic.styles'
import {
  BannerWrapper,
  BannerImage,
  BannerHeader,
  BannerButtonsWrapper,
  BannerFooter,
  BannerText,
  BannerLink,
} from './MyDataPage.styles'
import { ButtonLink, ButtonAmex } from '../../components/ButtonLink/ButtonLink'

export const MyDataPage = observer(() => {
  const store = useContext(StoreContext)

  return (
    <div>
      <TopBar />
      <InnerAppLayout>
        <NavMenu items={NAV_ROUTE_LIST} margin="24px 0 40px 0" />
        <InnerAppContent>
          <SubpageContentWrapper>
            <PageHeading
              title="Your data, your control."
              subtitle="In respect of the data subject enforcing their rights, it should first be noted that withdrawing consent, i.e. removing the controller's right to use your data, is not the same as the right of erasure. Consent is specific and unambiguous and is given for a particular activity."
            />
            <BannerWrapper>
              <BannerImage src={Banner} />
              <BannerHeader>
                Trust is critical to your lifestyle and well-being
              </BannerHeader>
              <BannerText>
                Amex wants you to fly high, and know that Amex is actively
                taking measures to empower you to make inform decisions and
                respect your rights.
              </BannerText>

              <BannerButtonsWrapper>
                <ButtonLink
                  styles={{ ...ButtonAmex }}
                  label="View your data"
                  href="view-my-data"
                />
                <ButtonLink
                  styles={{ ...ButtonAmex }}
                  label="Right to withdraw consent"
                />
                <ButtonLink
                  styles={{ ...ButtonAmex }}
                  label="Right to erasure"
                />
              </BannerButtonsWrapper>

              <BannerFooter>
                Find out more about the American Express Data Protection and
                Privacy Principles {'  '}
                <BannerLink
                  target="_blank"
                  href="https://www.americanexpress.com/icc/data-protection-and-privacy-principles.html"
                >
                  https://www.americanexpress.com/icc/data-protection-and-privacy-principles.html
                </BannerLink>
              </BannerFooter>
            </BannerWrapper>
          </SubpageContentWrapper>
        </InnerAppContent>
      </InnerAppLayout>
    </div>
  )
})
