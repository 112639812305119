import React from 'react'
import styled from 'styled-components'

interface ContainerProps {
  width?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '')};
  aspect-ratio: 1;
  padding: ${(props) => (props.padding ? props.padding : '')};

  svg {
    width: 100%;
    height: auto;
  }
`

interface CardsIconProps {
  width?: string
  padding?: string
}

export const CardsIcon = ({ width, padding }: CardsIconProps) => {
  return (
    <Container width={width ? width : '24px'} padding={padding ? padding : '0'}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="card_membership">
          <g id="Group 2">
            <path
              id="Subtract"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 3H19C20.11 3 21 3.89 21 5V8H19V5H3V11H6V14H3V16H6V18H3C1.89 18 1 17.11 1 16V5C1 3.89 1.89 3 3 3Z"
              fill="currentColor"
            />
            <path
              id="Vector"
              d="M22 6H6C4.89 6 4 6.89 4 8V19C4 20.11 4.89 21 6 21H10H18H22C23.11 21 24 20.11 24 19V8C24 6.89 23.11 6 22 6ZM22 19H6V17H22V19ZM22 14H6V8H22V14Z"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </Container>
  )
}
