import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../components/App'
import { FooBar } from '../../components/FooBar/FooBar'
import { TopBar } from '../../components/TopBar/TopBar'
import { Container } from './LoginSuccessPage.styles'

export const LoginSuccessPage = observer(() => {
  const store = useContext(StoreContext)

  return (
    <Container>
      <TopBar />

      <h1>Logged in</h1>

      <FooBar />
    </Container>
  )
})
