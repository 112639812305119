import axios from 'axios'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { signicatTheme } from '../constants/signicat.theme'

interface StepInterface {
  order: number
  completed: boolean
}

export class AppStateStore {
  rootStore
  isAuthorized = false
  initialAuthCheck = false
  theme: any = signicatTheme
  pageWidth: number = 0
  pageHeight: number = 0
  userFullName: string = 'Igor Kaliciński'
  userFirstName: string = 'Igor'
  userLastName: string = 'K'
  activeCard: string = ''

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound setIsAuthorized(auth: boolean): void {
    this.isAuthorized = auth
  }

  @action.bound setTheme(theme): void {
    this.theme = theme
  }

  @action.bound setPageWidth(width: number): void {
    this.pageWidth = width
  }

  @action.bound setPageHeight(height: number): void {
    this.pageHeight = height
  }

  @action
  async checkLogin() {
    try {
      const res = await axios.get<{
        firstName: string
        lastName: string
        fullName: string
      }>(
        `https://onboarding-amex-proxy-89e69358ff2a.herokuapp.com/get-user-data`,
        {
          withCredentials: true,
        }
      )

      console.log(res)

      runInAction(() => {
        const { firstName, lastName, fullName } = res.data

        this.userFirstName = firstName
        this.userLastName = lastName
        this.userFullName = fullName

        this.initialAuthCheck = true
        this.isAuthorized = true
        this.rootStore.Router.setLocation(window.location.pathname.substring(1))
      })
    } catch (e) {
      runInAction(() => {
        this.initialAuthCheck = true
        this.isAuthorized = true // set to "true" to skip authorization for local development
        this.rootStore.Router.setLocation(window.location.pathname.substring(1))
      })
      console.log(e)
    }
  }

  @action.bound setActiveCard(id: string): void {
    this.activeCard = id
  }
}
