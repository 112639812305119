import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import AELogo from '../../assets/americanExpressLogoMobile.png'
import { StoreContext } from '../../components/App'
import { Button, ButtonAmex } from '../Button/Button'
import { AmexLogo, BurgerIcon, Container } from './MockedTop.styles'

export const MockedTop = observer(() => {
  const store = useContext(StoreContext)

  return (
    <Container>
      <div>
        <BurgerIcon>
          <g
            id="Group 2124512"
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
          >
            <path
              id="Vector 313"
              d="M1 1H18"
              stroke="#808694"
              stroke-width="2"
              stroke-linecap="square"
            />
            <path
              id="Vector 314"
              d="M1 7H18"
              stroke="#808694"
              stroke-width="2"
              stroke-linecap="square"
            />
            <path
              id="Vector 315"
              d="M1 13H18"
              stroke="#808694"
              stroke-width="2"
              stroke-linecap="square"
            />
          </g>
        </BurgerIcon>
        <AmexLogo alt="Amex" src={AELogo} />
      </div>
      <Button
        label="Log in"
        styles={{
          ...ButtonAmex,
          width: '99px',
          padding: '0',
          height: '40px',
          margin: '7px 20px 0 0',
        }}
        onClick={() => {
          console.log()
        }}
      />
    </Container>
  )
})
