import styled from 'styled-components'

interface StyledButtonProps {
  width?: string
  minWidth?: string
  height?: string
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
  lineHeight?: string
  bgColor?: string
  padding?: string
  margin?: string
  border?: string
  outline?: string
  color?: string
  borderRadius?: string
}

export const StyledButton = styled.button.attrs({
  className: 'sc-btn',
})<StyledButtonProps>`
  width: ${(props) => (props.width ? props.width : 'auto')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'none')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : 'Inter')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '20px')};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgba(125, 81, 218, 1)'};
  padding: ${(props) => (props.padding ? props.padding : '10px 24px')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  border: ${(props) => (props.border ? props.border : 'none')};
  outline: ${(props) => (props.outline ? props.outline : 'none')};
  color: ${(props) => (props.color ? props.color : '#FFFFFF')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '34px'};
  cursor: pointer;
`
