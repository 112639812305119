import styled from 'styled-components'

interface ContainerProps {
  margin?: string
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  font-family: 'Inter';
  margin: ${(props) => (props.margin ? props.margin : '40px 0 32px')};
`

export const Headings = styled.div`
  width: 100%;
`

export const Toolbox = styled.div``

export const Header = styled.h1`
  font-size: 28px;
  line-height: 48px;
  color: #272142;
  margin: 0 0 4px 0;
  padding: 0;
  font-weight: 600;
`

export const Subtitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #767676;
`
