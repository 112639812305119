import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'

import { StoreContext } from '../App'

import {
  Avatar,
  Container,
  UserWrapper,
  UserName,
  RightSide,
  UserInitials,
} from './TopBar.styles'
import { AmexLogo } from '../../components/Icons/AmexLogo'
import AvatarMock from '../../assets/avatar_mock.jpg'

export const TopBar = observer(() => {
  const store = useContext(StoreContext)
  const { userFullName, userFirstName, userLastName } = store.AppState

  return (
    <Container>
      <AmexLogo width="46px" height="46px" />

      <RightSide>
        <UserWrapper>
          {/* <Avatar src={AvatarMock} /> */}
          <UserInitials>{userFirstName[0] + userLastName[0]}</UserInitials>
          <UserName>{userFullName}</UserName>
        </UserWrapper>
      </RightSide>
    </Container>
  )
})
